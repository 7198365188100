import React from "react";
import { BrowserRouter as Router, Switch, Route  } from "react-router-dom";
// Pages
import Home from "./pages/Home/Home";
import ToS from "./pages/ToS/ToS";
import PrivPol from "./pages/PrivPol/PrivPol";

// import Login from "./pages/Auth/Login/Login";
// import Register from "./pages/Auth/Register/Register";
// import Test from "./pages/Test/Test";
// const SwitchWithSlide = animateSwitch(Switch, SlideOut);
// console.log(Route.location)
export default class Routes extends React.Component {
  
  render() {

    return (
      <div>
        <Router>
            <Switch>
              <Route exact path="/privacypolicy" component={PrivPol} />
              <Route exact path="/termsofservice" component={ToS} />
              <Route exact path="/" component={Home} />
            </Switch>
        </Router>
      </div>
    );
  }
}
