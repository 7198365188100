import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmailSnackbar from './Snackbar/EmailSnackbar';

export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(false);
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
      console.log("i am called")
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="dialogParent">
      <Button onClick={handleClickOpen}> 
        Support
      </Button>
      <Dialog
        fullScreen={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="supportDialog">
          <DialogTitle id="responsive-dialog-title">{"Contact Oga Fitness"}</DialogTitle>
          <DialogContentText >
            Our social platforms
          </DialogContentText>
          <div className="socialPlatformTitle"></div>
          <div className="IconBar">
            <a href="https://facebook.com/ogafit" target="_blank"  rel="noopener noreferrer">
              <img src="assets/images/fac.svg" class="SingleIcons" alt="logo"/>
            </a>
            <a href="https://www.linkedin.com/company/oga-fit/" target="_blank"  rel="noopener noreferrer">
              <img src="assets/images/link.svg" class="SingleIcons" alt="logo"/>
            </a>
            <a href="https://twitter.com/oga_fit" target="_blank"  rel="noopener noreferrer">
              <img src="assets/images/twit.svg" class="SingleIcons" alt="logo"/>
            </a>
            <a href="https://www.instagram.com/oga.fitness/" target="_blank"  rel="noopener noreferrer">
              <img src="assets/images/inst.svg" class="SingleIcons" alt="logo"/>
            </a>
          </div>
          <DialogContentText>
            Alternative support options
          </DialogContentText>
          <div>
            <a href="https://discord.ogafit.com/" class="supportTBButtonLink" target="_blank"  rel="noopener noreferrer">
              <div className="supportJoinTB">
                <span className="supportJoinTBText">Join Team Bicep</span>
                <img src="assets/images/disc.svg" class="ButtonIcons" alt="logo" />
              </div>
            </a>
          </div>
          <div>
            <a href="mailto:support@ogafit.com" class="supportTBButtonLink" target="_blank"  rel="noopener noreferrer">
              <div className="supportJoinTB">
                <span className="supportJoinTBText">Email Us</span>
                <img src="assets/images/email.svg" class="ButtonIcons" alt="logo" />
              </div>
            </a>
            <EmailSnackbar></EmailSnackbar>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}